import { CurvyLineRender } from '../audiogram/CurvyLineRender/CurvyLineRender';
import { DefaultRender } from '../audiogram/DefaultRender/DefaultRender';
import { DiagonalRender } from '../audiogram/DiagonalRender/DiagonalRender';
import { FPS } from '../audiogram/Video';
import { FullSizeRender } from '../audiogram/FullSizeRender/FullSizeRender';
import {
  HIDDEN_NAVBAR_ROUTES,
  PEXELS_KEY_LIST,
  UNSPLASH_ACCESS_KEY,
  UPLOAD_BUCKET
} from '../models/constant';
import { IDropdownSettings } from '@/components/Shared/Dropdown';
import { ImageRender } from '../audiogram/ImageRender/ImageRender';
import { PlayerRef } from '@remotion/player';
import { PodArtRender } from '../audiogram/PodArtRender/PodArtRender';
import { PodcastRender } from '../audiogram/PodcastRender/PodcastRender';
import { ProductHuntRender } from '../audiogram/ProductHuntRender/ProductHuntRender';
import { SlideshowCRFValue } from '@/slideshow/types';
import { SubtitleItem } from '../audiogram/components/Subtitles';
import {
  SubtitleOptions,
  VideoTemplateColours,
  VideoTemplateOptions
} from '../types/MediaManager';
import { TextOutput } from '../audiogram/components/TextSequence';
import { UnsplashErrors, UnsplashImage } from '../models/Unsplash';
import { isUserUploadedVideo } from '@/slideshow/utils/asset';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

enum PageNames {
  ROOT = '/',
  PROFILE_ROOT = '/@',
  ME = '/me',
  SIGNUP = '/signup',
  CREATE = '/create',
  LOGIN = '/login',
  EMAIL_SIGNUP = '/email-signup',
  EMAIL_LOGIN = '/email-login',
  SET_PROFILE = '/set-profile',
  SET_SLUG = '/set-slug',
  EXPORT_VIDEO = '/export',
  CREATE_SLIDESHOW = '/create-slideshow',
  EXPORT_SLIDESHOW = '/export-slideshow',
  GENERATE_VIDEO = '/generate',
  GENERATE_SLIDESHOW = '/generate-slideshow',
  GUIDE = '/guide',
  ERROR = '/404',
  BLOG = '/blog',
  PRICING = '/pricing',
  CHANGE_LOG = '/change-log',
  ABOUT_US = '/about-us',
  FAQ = '/faq',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  HISTORY = '/history',
  SETTINGS = '/settings',
  AUDIOGRAM = '/audiogram',
  WAITLIST = '/waitlist',
  AI_VIDEO = '/product/ai-slideshow-video-generator'
}

enum ExternalUrls {
  APPSUMO_PROMO = 'https://appsumo.8odi.net/21eqB7',
  AFFILIATE = 'https://affiliate.jupitrr.com/',
  PRODUCT_HUNT = 'https://www.producthunt.com/posts/jupitrr-ai'
}

const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

enum LocalStorageItems {
  URL_SLUG = 'username',
  ACCESS_TOKEN = 'accessToken',
  DISPLAY_NAME = 'displayName',
  AVATAR = 'avatar',
  IS_WEBVIEW = 'is_webview',
  MEDIA_MANAGER = 'media_manager',
  AI = 'ai',
  HIDE_SLIDESHOW_HELPER_TOOLTIP = 'hide_slideshow_helper_tooltip'
}

enum SessionStorageItems {
  FROM_APPSUMO = 'from_appsumo'
}

const getItem = (item: LocalStorageItems): string => {
  return typeof window !== 'undefined' && window.localStorage.getItem(item);
};
const setItem = (key: LocalStorageItems, value: string): void =>
  typeof window !== 'undefined' && window.localStorage.setItem(key, value);

const getSessionItem = (item: SessionStorageItems): string => {
  return typeof window !== 'undefined' && window.sessionStorage.getItem(item);
};
const unsetSessionItem = (item: SessionStorageItems) => {
  return (
    typeof window !== 'undefined' && window.sessionStorage.removeItem(item)
  );
};
const setSessionItem = (key: SessionStorageItems, value: string): void =>
  typeof window !== 'undefined' && window.sessionStorage.setItem(key, value);
// const deleteItem = (key: LocalStorageItems): void => typeof window !== 'undefined' && window.localStorage.removeItem(key);
const clearAllItems = (): void =>
  typeof window !== 'undefined' && window.localStorage.clear();

const detectWebView = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);
  if (ios) {
    if (safari) setItem(LocalStorageItems.IS_WEBVIEW, false.toString());
    // browser
    else if (!safari) setItem(LocalStorageItems.IS_WEBVIEW, true.toString()); // webview
  } else setItem(LocalStorageItems.IS_WEBVIEW, false.toString()); // not iOS
};

/** Replaces any variables inside NativeLanding.json from a template object.
 * Usage: stringTemplateParser('Hello my name is {{ name }}.', { name: 'James' });
 * Output: 'Hello my name is James'.
 * Remember to use same template literal as the one defined inside the template object.
 * Reference: https://stackoverflow.com/a/56920019
 * @param nativeAppText
 * @param templateObj
 * @return string
 */
const stringTemplateParser = (
  nativeAppText: string,
  templateObj: { [p: string]: string }
): string => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
  return nativeAppText.replace(templateMatcher, (substring, value) => {
    value = templateObj[value];
    return value;
  });
};

declare global {
  interface Window {
    MSStream: any;
  }
}

const isIOS =
  typeof window !== 'undefined' &&
  /iP(ad|od|hone)/i.test(navigator.userAgent) &&
  !window.MSStream;

function isSafariNonMobile() {
  var userAgent = navigator.userAgent;

  // Check for Safari browser
  var isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  // Check for mobile device
  var isMobile = /Mobi|Android/i.test(userAgent);

  return isSafari && !isMobile;
}

// isIOSSafari is contingent on isIOS, so doesn't need the regex to check the device name i.e. iPad/ iPod/ iPhone.
const isIOSSafari =
  typeof window !== 'undefined' &&
  /WebKit/i.test(navigator.userAgent) &&
  !/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent);

const forceDownload = (blob: any, filename) => {
  const url = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.download = filename;
  a.href = url;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

// Current blob size limit is around 500 MB for browsers
const downloadResource = (
  url: string,
  fileName: string = 'Jupitrr Export',
  fileType: 'mp4' | 'mp3'
) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: new Headers({
        Origin: location.origin
      }),
      mode: 'cors'
    })
      .then((response) => response.blob())
      .then(async (blob) => {
        await forceDownload(blob, `${fileName}-jupitrr.${fileType}`);
        // await timeout(2000);
        resolve(true);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
};

const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

const getDuration = (url: string, next: any) => {
  if (
    typeof window === 'undefined' ||
    typeof window.AudioContext === 'undefined'
  )
    return;
  const request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.setRequestHeader('Cache-Control', 'no-cache');
  request.responseType = 'arraybuffer';
  request.onload = function () {
    const audioContext = new window.AudioContext();
    if (!audioContext) return;
    audioContext.decodeAudioData(
      request.response,
      function (buffer) {
        let duration = buffer.duration;
        next(duration);
      },
      function (err) {
        next(null);
      }
    );
  };
  request.send();
};

// Example: aug-12-2023-06-59pm
function generateFormattedTime() {
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec'
  ];

  const currentDate = new Date();
  const month = months[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  let hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const period = hours >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours || 12; // 0 should be converted to 12

  const formattedTime = `${month}-${day}-${year}-${hours
    .toString()
    .padStart(2, '0')}-${minutes.toString().padStart(2, '0')}${period}`;
  return formattedTime;
}

// ref: https://stackoverflow.com/a/41245574

interface WeservConfig {
  width?: number;
  height?: number;
  quality?: number; //0-100
  fileType?: 'jpg' | 'png' | 'gif' | 'tiff' | 'webp' | 'json';
}

const cacheImageUrl = (originalUrl: string, config: WeservConfig) => {
  let url = `https://images.weserv.nl/?url=${originalUrl}`;
  if (config.fileType) url += `&output=${config.fileType}`;
  if (config.width) url += `&width=${config.width}`;
  if (config.height) url += `&height=${config.height}`;
  if (config.quality) url += `&q=${config.quality}`;
  return url;
};

const sleep = (seconds: number) =>
  new Promise((resolve) => setTimeout(resolve, seconds * 1000));

const fetchSlideshowSubtitleIfTheyExist = async (
  uid: string,
  slideshowId: string
): Promise<TextOutput[] | SubtitleItem[]> => {
  const url = `https://${UPLOAD_BUCKET}.s3.ap-southeast-1.amazonaws.com/${uid}/slideshow-${slideshowId}-source.json?q=${new Date().getTime()}`;
  const res = await fetch(url);
  if (res.status === 200) return await res.json();
  else return null;
};

/**
 * Fetch the relevant subtitle json if it exists.
 * Adds a query param to the url to force the browser to reset the potentially cached response.
 * @param uid string
 * @param answerId string
 * @param subtitleType SubtitleOptions
 * @return Promise<Text[] | SubtitleItem[]>
 */
const fetchAudiogramSubtitlesIfTheyExist = async (
  uid: string,
  answerId: string,
  subtitleType: SubtitleOptions,
  defaultVersion?: boolean
): Promise<TextOutput[] | SubtitleItem[]> => {
  let getSourceBasedOnSubtitleType = '';
  if (subtitleType === SubtitleOptions.WORD_BY_WORD) {
    if (defaultVersion) {
      getSourceBasedOnSubtitleType = '-source-raw.json';
    } else {
      getSourceBasedOnSubtitleType = '-source.json';
    }
  } else {
    if (defaultVersion) {
      getSourceBasedOnSubtitleType = '-pretty-original.json';
    } else {
      getSourceBasedOnSubtitleType = '-pretty.json';
    }
  }
  const url = `https://${UPLOAD_BUCKET}.s3.ap-southeast-1.amazonaws.com/${uid}/${answerId}${getSourceBasedOnSubtitleType}?q=${new Date().getTime()}`;
  const res = await fetch(url);
  if (res.status === 200) return await res.json();
  else return null;
};

const UNSPLASH_BASE_URL = 'https://api.unsplash.com/';

const getRandomUnsplashImage = async (): Promise<UnsplashImage> => {
  try {
    const url = `${UNSPLASH_BASE_URL}photos/random?client_id=${UNSPLASH_ACCESS_KEY}`;
    const data = await fetch(url);
    let response: UnsplashImage | UnsplashErrors = await data.json();
    const fallbackImage = {
      id: uuidv4(),
      alt_description: 'fallback_banner',
      urls: {
        regular:
          'https://jpt-admin.s3.ap-southeast-1.amazonaws.com/website/landing/live_preview/fallback_banner.jpg'
      }
    };
    if ('errors' in response) return fallbackImage;
    if (data.ok) {
      response.alt_description =
        response.alt_description !== null ? response.alt_description : 'banner';
      return response;
    } else return fallbackImage;
  } catch (err) {
    console.error(err);
    getRandomUnsplashImage().then();
  }
};

const getSelectedComponent = (template: VideoTemplateOptions | 'slideshow') => {
  switch (template) {
    case VideoTemplateOptions.DEFAULT:
      return DefaultRender;
    case VideoTemplateOptions.PRODUCT_HUNT:
      return ProductHuntRender;
    case VideoTemplateOptions.WITH_IMAGE:
      return ImageRender;
    case VideoTemplateOptions.FULL_SIZE:
      return FullSizeRender;
    case VideoTemplateOptions.PODCAST:
      return PodcastRender;
    case VideoTemplateOptions.PODART:
      return PodArtRender;
    case VideoTemplateOptions.CURVY_LINE:
      return CurvyLineRender;
    case VideoTemplateOptions.DIAGONAL:
      return DiagonalRender;
  }
};

const getSelectedPresetTemplate = (
  template: VideoTemplateOptions
): { textColor: VideoTemplateColours | string; color: string } => {
  switch (template) {
    case VideoTemplateOptions.DEFAULT:
      return {
        textColor: VideoTemplateColours.WHITE,
        color: VideoTemplateColours.GRADIENT_YELLOW_PINK
      };
    case VideoTemplateOptions.WITH_IMAGE:
      return {
        textColor: VideoTemplateColours.WHITE,
        color: VideoTemplateColours.GRADIENT_PURPLE
      };
    case VideoTemplateOptions.PRODUCT_HUNT:
      return {
        textColor: VideoTemplateColours.BLACK,
        color: VideoTemplateColours.GRADIENT_ORANGE
      };
    case VideoTemplateOptions.PODCAST:
      return {
        textColor: VideoTemplateColours.WHITE,
        color: VideoTemplateColours.GRADIENT_BLUE_GREEN
      };
    case VideoTemplateOptions.FULL_SIZE:
      // added a check of HexColorPicker AND HexColorInput to handle null background colors
      return { textColor: VideoTemplateColours.WHITE, color: null };
    case VideoTemplateOptions.PODART:
      // added a check of HexColorPicker AND HexColorInput to handle null background colors
      return {
        textColor: VideoTemplateColours.WHITE,
        color: VideoTemplateColours.CORAL_RED
      };
    case VideoTemplateOptions.CURVY_LINE:
      // added a check of HexColorPicker AND HexColorInput to handle null background colors
      return {
        textColor: VideoTemplateColours.WHITE,
        color: VideoTemplateColours.VIVID_BLUE
      };
    case VideoTemplateOptions.DIAGONAL:
      // added a check of HexColorPicker AND HexColorInput to handle null background colors
      return {
        textColor: VideoTemplateColours.WHITE,
        color: VideoTemplateColours.GRADIENT_BLUE
      };
    default:
      return {
        textColor: VideoTemplateColours.WHITE,
        color: VideoTemplateColours.GRADIENT_YELLOW_PINK
      };
  }
};

function getElementOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    x: rect.x,
    y: rect.y
  };
}

function isiOS() {
  const userAgent = window.navigator.userAgent;
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

function getHighlightedTextSentence({
  content,
  text
}: {
  content: string;
  text: string;
}) {
  const startIdx = content.indexOf(text);

  const endIdx = startIdx + text.length;
  const sentenceEndChars = ['.', '!', '?'];
  let sentenceStart = startIdx;
  let sentenceEnd = endIdx;

  // Traverse left to find sentence start
  while (
    sentenceStart > 0 &&
    !sentenceEndChars.includes(content[sentenceStart - 1])
  ) {
    sentenceStart--;
  }

  // Traverse right to find sentence end
  while (
    sentenceEnd < content.length &&
    !sentenceEndChars.includes(content[sentenceEnd])
  ) {
    sentenceEnd++;
  }

  return content.slice(sentenceStart, sentenceEnd).trim();
}

function rotatePexelsKey() {
  return PEXELS_KEY_LIST[0];
}

const containsNonEnglishCharacters = (subtitles: TextOutput[]) => {
  const nonEnglishWordPattern = /[^\x00-\x7F]+/;
  const englishWordPattern = /[\x00-\x7F]+/;

  let englishWords: string[] = [];
  let nonEnglishWords: string[] = [];

  subtitles.forEach((subtitle) => {
    if (nonEnglishWordPattern.test(subtitle.c)) {
      nonEnglishWords.push(subtitle.c);
    } else if (englishWordPattern.test(subtitle.c)) {
      englishWords.push(subtitle.c);
    }
  });
  return englishWords.length <= nonEnglishWords.length;
};

const isMediaAGif = (dropdownSettings: IDropdownSettings) =>
  dropdownSettings?.activeAsset?.type === 'gif';

const isMediaAImage = (dropdownSettings: IDropdownSettings) =>
  dropdownSettings?.activeAsset?.type === 'image';

const isMediaAWebImage = (dropdownSettings: IDropdownSettings) =>
  dropdownSettings?.activeAsset?.type === 'web_image';

const isOverlay = (dropdownSettings: IDropdownSettings) =>
  dropdownSettings?.activeAsset?.type === 'overlay';

const seekToSecond = (
  from: string,
  videoPreviewRef: React.RefObject<PlayerRef>
) => {
  const timestamp = Number(from) * FPS + 5;
  if (!videoPreviewRef || !videoPreviewRef.current) return;
  videoPreviewRef.current.pause();
  videoPreviewRef.current.seekTo(timestamp);
};

const hideNewNav = (
  pathname: string
): {
  isNavHidden: boolean;
  isInternalHeaderHidden: boolean;
  isPublicHeaderHidden: boolean;
} => {
  const hideNavFromRoutes: {
    path: string;
    exact: boolean;
    isHeaderHidden: boolean;
  }[] = HIDDEN_NAVBAR_ROUTES;

  let isNavHidden = false;
  let isHeaderHidden = false;

  isNavHidden = hideNavFromRoutes.some((route) =>
    route.exact ? pathname === route.path : pathname.includes(route.path)
  );

  isHeaderHidden = hideNavFromRoutes.some((route) =>
    route.exact
      ? pathname === route.path
      : pathname.includes(route.path)
      ? route.isHeaderHidden
      : false
  );

  return {
    isNavHidden,
    isInternalHeaderHidden:
      isHeaderHidden || pathname.includes('export-slideshow'),
    isPublicHeaderHidden:
      !isHeaderHidden || pathname.includes('export-slideshow')
  };
};

function getCRFValue(videoQuality): number {
  switch (videoQuality) {
    case 'Studio':
      return SlideshowCRFValue.Studio;
    case 'Social Media':
      return SlideshowCRFValue.SocialMedia;
    case 'Web':
      return SlideshowCRFValue.Web;
    default:
      return SlideshowCRFValue.SocialMedia;
  }
}

function formatNumber(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + 'B'; // Billion
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + 'M'; // Million
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + 'K'; // Thousand
  } else {
    return num.toString(); // Less than 1000
  }
}

const MAX_INT32 = Math.pow(2, 31) - 1;
const getAdjustedPosition = (
  position: { x: number; y: number },
  mode: string
) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const dropdownWidth = 200;
  let dropdownHeight = 350;
  const padding = 20;
  if (mode === 'edit') {
    dropdownHeight = 200;
  }

  return {
    left: Math.min(position.x, viewportWidth - dropdownWidth - padding) + 'px',
    top: Math.min(position.y, viewportHeight - dropdownHeight - padding) + 'px'
  };
};

// Add function to check if URL exists
const checkUrlExists = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

const getCompressedVideoUrl = async (url: string) => {
  if (isUserUploadedVideo(url)) {
    const baseUrl = url.replace('.mp4', '');
    const compressedUrl = `${baseUrl}_compressed.mp4`;
    const compressedExists = await checkUrlExists(compressedUrl);
    return compressedExists ? compressedUrl : url;
  }
  return url;
};

export {
  isiOS,
  isSafariNonMobile,
  validateEmail,
  PageNames,
  ExternalUrls,
  detectWebView,
  clearAllItems,
  getElementOffset,
  // deleteItem,
  setItem,
  getItem,
  getSessionItem,
  unsetSessionItem,
  setSessionItem,
  LocalStorageItems,
  SessionStorageItems,
  stringTemplateParser,
  isIOS,
  isIOSSafari,
  downloadResource,
  getRandomInt,
  getDuration,
  generateFormattedTime,
  cacheImageUrl,
  sleep,
  fetchSlideshowSubtitleIfTheyExist,
  fetchAudiogramSubtitlesIfTheyExist,
  UNSPLASH_BASE_URL,
  getRandomUnsplashImage,
  getSelectedComponent,
  getSelectedPresetTemplate,
  getHighlightedTextSentence,
  rotatePexelsKey,
  containsNonEnglishCharacters,
  isMediaAGif,
  isMediaAImage,
  isMediaAWebImage,
  seekToSecond,
  hideNewNav,
  getCRFValue,
  isOverlay,
  formatNumber,
  MAX_INT32,
  getAdjustedPosition,
  getCompressedVideoUrl
};
