import 'firebase/auth';
import {
  ExternalUrls,
  LocalStorageItems,
  PageNames,
  getItem,
  setItem
} from '../../utils/generalUtilities';
import { NewJPButton } from '../GeneralComponents';
import { P1 } from '../OldGeneralComponents';
import { ScreenTypes, useScreenType } from '../../hooks/useScreenType';
import { useRouter } from 'next/router';
import { useUser } from '@/hooks/useUser';
import AvatarWithDropdown from '../JPHeader/AvatarWithDropdown';
import CreateNewButton from '../JPHeader/CreateNewButton';
import DesktopLinks from '../JPHeader/DesktopLinks';
import Icon, { IconType } from './Icon';
import Logo from '../JPHeader/Logo';
import MobileLinks from '../JPHeader/MobileLinks';
import React, { useEffect, useState } from 'react';
import User from '../../models/User';
import locale from '../../locales/en/NativeLanding';

const Announcement = () => {
  // const { userAuth, hasPremium, user } = useUser();
  // if (userAuth && user && hasPremium !== 'free') return <></>;
  return (
    <div className="block w-full justify-center bg-blue-700 px-3 py-3 text-center text-sm text-white md:flex-row">
      {/* <div className="flex w-full flex-col justify-center bg-blue-700 px-3 py-3 text-center text-sm text-white md:flex-row"> */}
      <a className="mx-1 inline" href={PageNames.PRICING}>
        <span className="text-white">
          {' '}
          🎉 Happy Holiday - enjoy 40% off Annual Plan
        </span>
      </a>
    </div>
  );
};

const JPHeader = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [userName, setUserName] = useState('');
  const [slug, setSlug] = useState<string>(getItem(LocalStorageItems.URL_SLUG));
  const [isSlideshow, setIsSlideshow] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false
  const [isLoaded, setIsLoaded] = useState(false);
  const screenType = useScreenType();
  const router = useRouter();
  useEffect(() => setIsLoggedIn(!!getItem(LocalStorageItems.ACCESS_TOKEN)), []);
  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 500);
  }, []);

  const displayMyProfile = async () => {
    const accessToken = getItem(LocalStorageItems.ACCESS_TOKEN);
    if (!accessToken) return;
    try {
      const me = await User.me(getItem(LocalStorageItems.ACCESS_TOKEN));
      if (me === null) return;
      setIsSlideshow(me.email.includes('slideshow@jupitrr'));
      setUserName(me?.display_name || me?.email || '');

      if (getItem(LocalStorageItems.URL_SLUG)) {
        setSlug(getItem(LocalStorageItems.URL_SLUG));
      } else {
        if (me.avatar) setAvatar(me.avatar);
        if (me.url_slug) setSlug(me.url_slug);
        setItem(LocalStorageItems.AVATAR, me.avatar || '');
        setItem(LocalStorageItems.URL_SLUG, me.url_slug);
      }
    } catch (err) {
      // New account creations take time to create the mongoDb instance -- suppress gracefully.
      console.log(err.code);
    }
  };

  useEffect(() => {
    displayMyProfile().then();
  }, []);
  const isDesktop =
    screenType === ScreenTypes.web || screenType === ScreenTypes.desktop;
  return (
    <div className="sticky top-0 z-50 bg-white">
      <Announcement />
      <div className="relative flex flex-col">
        <div className="bg-color-white flex justify-between px-6 py-[18px]">
          {isLoaded && !isDesktop && isNavOpen && (
            <div
              className={`visible absolute left-0 top-[88px] z-40 flex h-screen w-full flex-col items-center bg-white p-6 ${
                !isDesktop && isNavOpen
                  ? 'visible opacity-100'
                  : 'invisible opacity-0'
              }`}
            >
              <MobileLinks onClick={() => setIsNavOpen(!isNavOpen)} />
            </div>
          )}
          <div className="z-30 m-auto flex w-full max-w-[1280px] flex-row justify-between">
            <div className="flex flex-row items-center justify-start">
              <Logo withName={true} />
              {isDesktop ? <DesktopLinks /> : null}
            </div>
            <div className="relative flex flex-row items-center">
              {isLoggedIn ? (
                <>
                  <div className="mr-4">
                    <CreateNewButton />
                  </div>
                  <AvatarWithDropdown
                    avatar={avatar}
                    isLoggedIn={isLoggedIn}
                    slug={slug}
                    isSlideshow={isSlideshow}
                    userName={userName}
                  />
                </>
              ) : (
                <>
                  {isDesktop ? (
                    <>
                      <a
                        onClick={() => router.push(PageNames.LOGIN)}
                        className="mr-[14px] cursor-pointer"
                      >
                        <P1 className="rounded-md px-[18px] py-[10px] text-grey-500 hover:bg-blue-50">
                          {locale.common.login}
                        </P1>
                      </a>
                      <NewJPButton
                        text={locale.common.sign_up}
                        onClick={() => router.push(PageNames.SIGNUP)}
                        size="md"
                      />
                    </>
                  ) : (
                    <div
                      className="absolute -right-[10px] cursor-pointer transition-all"
                      onClick={() => setIsNavOpen(!isNavOpen)}
                    >
                      {isNavOpen ? (
                        <Icon type={IconType.Cross} size={24} />
                      ) : (
                        <Icon type={IconType.Hamburger} size={24} />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JPHeader;
export { Announcement };
