import { PageNames } from '../../utils/generalUtilities';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Avatar from '../Shared/Avatar';
import Menu from '../Shared/Menu';

const MobileLinks = ({
  isLoggedIn,
  slug,
  isSlideshow,
  avatar,
  userName
}: {
  isLoggedIn: boolean;
  slug: string;
  isSlideshow: boolean;
  avatar: string;
  userName: string;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const goToSettings = () => router.push(PageNames.SETTINGS);
  const goToMe = () => router.push(PageNames.ME);
  return (
    <>
      <Avatar
        src={avatar}
        size={40}
        style={{ cursor: 'pointer' }}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        userName={userName}
      />
      {isMenuOpen ? (
        <Menu
          isMenuOpen={isMenuOpen}
          onLeave={() => setIsMenuOpen(false)}
          customContainerStyle={{
            top: 50
          }}
          menuOptions={[
            {
              title: 'Video library',
              isVisible: true,
              onClick: goToMe
            },
            {
              title: 'Settings',
              isVisible: true,
              onClick: goToSettings
            }
          ]}
        />
      ) : null}
    </>
  );
};

export default MobileLinks;
