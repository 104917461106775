import { IS_DEV_ENV } from './constant';
import moment from 'moment';

type PLAN_OPTIONS =
  | 'free'
  | 'starter'
  | 'creator'
  | 'pro'
  | 'jupitrr_tier1'
  | 'jupitrr_tier2'
  | 'jupitrr_tier3';


interface PlanData {
  name: 'Free' | 'Starter' | 'Creator' | 'Pro';
  currency: string;
  btnText: string;
  monthlyPrice: number;
  annualPrice: number;
  priceSubText: (interval?: 'monthly' | 'annually') => string;
  featuresHeader: string;
  features: string[];
  textColor: string;
}
const plans: PlanData[] = [
  {
    name: 'Free',
    currency: '$',
    monthlyPrice: 0,
    annualPrice: 0,
    featuresHeader: 'To start with:',
    features: [
      '5 mins AI Video',
      '10 mins audiogram',
      '720p export',
      'Unlimited Stock Asset B-roll'
    ],
    priceSubText: () => `Free forever`,
    btnText: 'Sign up',
    textColor: '#1f2937'
  },
  {
    name: 'Starter',
    currency: '$',
    monthlyPrice: 18,
    annualPrice: 10.80,
    featuresHeader: 'Everything in Free, plus',
    features: [
      '10 mins AI Video',
      '360 mins audiogram',
      '1080 HD export',
      'Social Media quality export',
      'Remove watermark'
    ],
    priceSubText: (int) => `per seat/month, billed ${int}`,
    btnText: 'Sign up',
    textColor: '#22c55e'
  },
  {
    name: 'Creator',
    currency: '$',
    monthlyPrice: 22,
    annualPrice: 13.20,
    featuresHeader: 'Everything in Starter, plus',
    features: [
      '360 mins AI Video',
      '600 mins audiogram',
      '30 mins per video',
      'Studio quality export',
      'Unlimited web image B-roll',
      'Download subtitles'
      // 'Auto B-roll'
    ],
    priceSubText: (int) => `per seat/month, billed ${int}`,
    btnText: 'Sign up',
    textColor: '#EA580C'
  },
  {
    name: 'Pro',
    currency: '$',
    monthlyPrice: 42,
    annualPrice: 25.20,
    featuresHeader: 'Everything in Creator, plus',
    priceSubText: (int) => `per seat/month, billed ${int}`,
    features: ['500 mins AI Video', 'Unlimited audiogram'],
    btnText: 'Sign up',
    textColor: '#2563eb'
  }
];

const featuresList = {
  'Video usage & export': {
    'AI Video Transcription': {
      value: {
        Free: '5mins',
        Starter: '10mins',
        Creator: '360mins',
        Pro: '500mins'
      }
    },
    'Audiogram Transcription': {
      value: {
        Free: '10mins',
        Starter: '360mins',
        Creator: '600mins',
        Pro: 'Unlimited'
      }
    },
    'Maximum file size': {
      value: {
        Free: 'Up to 50MB',
        Starter: 'Up to 100MB',
        Creator: 'Up to 200MB',
        Pro: 'Up to 200MB'
      }
    },
    'Maximum duration per video': {
      value: {
        Free: '1min',
        Starter: '10mins',
        Creator: '30mins',
        Pro: '30mins'
      }
    },
    'Export Resolution': {
      value: {
        Free: '720p',
        Starter: '1080 HD',
        Creator: '1080 HD',
        Pro: '2K (Coming soon)'
      }
    },
    'Export Quality (Compression)': {
      value: {
        Free: 'Social Media',
        Starter: 'Social Media',
        Creator: 'Studio',
        Pro: 'Studio'
      }
    }
  },
  'B-roll features': {
    'Stock image/ video': {
      value: {
        Free: 'checked',
        Starter: 'checked',
        Creator: 'checked',
        Pro: 'checked'
      }
    },
    GIFs: {
      value: {
        Free: 'checked',
        Starter: 'checked',
        Creator: 'checked',
        Pro: 'checked'
      }
    },
    'Upload your video/ image': {
      value: {
        Free: 'checked',
        Starter: 'checked',
        Creator: 'checked',
        Pro: 'checked'
      }
    },
    'Web image': {
      value: {
        Free: null,
        Starter: 'checked',
        Creator: 'checked',
        Pro: 'checked'
      }
    },
    'Generative AI image ': {
      badge: 'Coming soon',
      value: {
        Free: null,
        Starter: null,
        Creator: 'checked',
        Pro: 'checked'
      }
    },
    'AI text overlay': {
      value: {
        Free: null,
        Starter: null,
        Creator: 'checked',
        Pro: 'checked'
      }
    }
  },
  'Video making': {
    Subtitles: {
      value: {
        Free: 'checked',
        Starter: 'checked',
        Creator: 'checked',
        Pro: 'checked'
      }
    },
    'Download Subtitles': {
      value: {
        Free: null,
        Starter: 'checked',
        Creator: 'checked',
        Pro: 'checked'
      }
    },
    'Language Support': {
      value: {
        Free: '50+ languages',
        Starter: '50+ languages',
        Creator: '50+ languages',
        Pro: '50+ languages'
      }
    },
    'Audio to Video': {
      value: {
        Free: 'checked',
        Starter: 'checked',
        Creator: 'checked',
        Pro: 'checked'
      }
    }
    //, 'Auto B-roll': {
    //   value: {
    //     Free: null,
    //     Starter: null,
    //     Creator: 'checked',
    //     Pro: 'checked'
    //   }
    // }
  }
}

const AI_PLANS = ['creator', 'pro'];
const AUDIOGRAM_PLANS = ['free', 'starter'];
const PLANS = [...AI_PLANS, ...AUDIOGRAM_PLANS];

const ADMIN_FREE_PLANS: {
  [key: string]: {
    priceId: string;
    productId: string;
    paymentLink: string;
    months: number;
    link: string;
  };
} = !IS_DEV_ENV
    ? {
      THREE_MONTHS_CREATOR: {
        priceId: 'price_1NsrRMInAv50s8RRhLdRGV1C',
        productId: 'prod_OO7zUw4co10Xa9',
        paymentLink: 'plink_1NssnLInAv50s8RRftaSIDVh',
        months: 3,
        link: 'https://buy.stripe.com/6oE7tXfp42YCcla28d'
      },
      ONE_MONTH_CREATOR: {
        priceId: 'price_1OHuhBInAv50s8RRITwemnFo',
        productId: 'prod_OO7zUw4co10Xa9',
        paymentLink: 'plink_1OHutbInAv50s8RRhyr2nchc',
        months: 1,
        link: 'https://buy.stripe.com/6oE7tXfp42YCcla28d'
      }
    }
    : {
      THREE_MONTHS_CREATOR: {
        priceId: 'price_1NssnyInAv50s8RR7H7LXMWp',
        productId: 'prod_OOAsOMp6BvQ3kY',
        paymentLink: 'plink_1NssoEInAv50s8RRaI7TImZN',
        months: 3,
        link: 'https://buy.stripe.com/test_6oEdTwaqD1IW14Q5kq'
      },
      ONE_MONTH_CREATOR: {
        priceId: 'price_1NssnyInAv50s8RR7H7LXMWp',
        productId: 'prod_OOAsOMp6BvQ3kY',
        paymentLink: 'plink_1NssoEInAv50s8RRaI7TImZN',
        months: 1,
        link: 'https://buy.stripe.com/test_6oEdTwaqD1IW14Q5kq'
      }
    };

enum VideoResolution {
  '1080p' = 'landscape',
  '720p' = 'landscape720p'
}

enum VideoQuality {
  Studio = 'Studio',
  SocialMedia = 'Social Media',
  Web = 'Web'
}

type PremiumEnabledSettingsType = {
  resolutions: VideoResolution[];
  qualities: VideoQuality[];
};

const getPremiumEnabledSettings = (hasPremium: PLAN_OPTIONS): PremiumEnabledSettingsType => {
  switch (hasPremium) {
    case 'starter':
    case 'jupitrr_tier1':
      return {
        resolutions: [VideoResolution['720p'], VideoResolution['1080p']],
        qualities: [VideoQuality.SocialMedia, VideoQuality.Web]
      };
    case 'creator':
    case 'pro':
    case 'jupitrr_tier2':
    case 'jupitrr_tier3':
      return {
        resolutions: [VideoResolution['720p'], VideoResolution['1080p']],
        qualities: [
          VideoQuality.SocialMedia,
          VideoQuality.Web,
          VideoQuality.Studio
        ]
      };
    case 'free':
    default:
      return {
        resolutions: [VideoResolution['720p']],
        qualities: [VideoQuality.SocialMedia, VideoQuality.Web]
      };
  }
}

export {
  featuresList,
  ADMIN_FREE_PLANS,
  plans,
  VideoResolution,
  VideoQuality,
  getPremiumEnabledSettings
};
export type { PlanData, PLAN_OPTIONS, PremiumEnabledSettingsType };
