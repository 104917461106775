import 'firebase/auth';
import * as Sentry from '@sentry/nextjs';
import {
  LocalStorageItems,
  PageNames,
  getItem
} from '../utils/generalUtilities';
import { PLAN_OPTIONS } from '@/models/plans';
import { firestore } from '../models/firebase';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import User, { UserInfo } from '../models/User';
import firebase from 'firebase/app';
import posthog from 'posthog-js';

export interface UserContextValue {
  user: UserInfo;
  userAuth: firebase.User;
  hasWaitlistPermission: string;
  refreshMyInfo: (token) => void;
  hasPremium: PLAN_OPTIONS | null;
  hasPremiumOrNot: () => boolean;
}

const UserContext = createContext<UserContextValue | undefined>(undefined);

export const UserProvider = (props: any) => {
  const [userAuth, setUserAuth] = useState<firebase.User>();
  const [user, setUser] = useState<UserInfo>();
  const [hasWaitlistPermission, setHasWaitlistPermission] = useState<
    'loading' | 'nope' | 'offboarded'
  >('loading');
  const router = useRouter();
  const checkWaitlistStatus = async (me: firebase.User) => {
    const snap = await firestore.collection('waitlist').doc(me.email).get();
    const data = snap.data();

    if (!data || !data.offboard_status) {
      setHasWaitlistPermission('nope');
    } else {
      setHasWaitlistPermission('offboarded');
    }

    /* Set offboarded if slideshow page is landed */
    if (!window.location.pathname.includes('-slideshow')) return;
    setHasWaitlistPermission('offboarded');

    /* Record landed on create page */
    if (data && data.offboard_status === 'landed_on_create_page') return;
    const dataToUpdate: any = {
      offboard_status: 'landed_on_create_page',
      email: me.email,
      last_updated: new Date().toISOString()
    };
    if (data && (!data.display_name || data.display_name.trim().length === 0))
      dataToUpdate.display_name = me.displayName;
    await firestore
      .collection('waitlist')
      .doc(me.email)
      .set(dataToUpdate, { merge: true });
  };

  const refreshMyInfo = async (token?: string) => {
    let userInfo: UserInfo = await User.me(
      token || getItem(LocalStorageItems.ACCESS_TOKEN)
    );
    if (userInfo && userInfo.id) setUser(userInfo);
    const trackingData = {
      createdAt: userInfo.created_at,
      hasPremium: !!userInfo.current_premium
    };
    if (!!userInfo.current_premium) {
      Object.assign(trackingData, {
        purchaseSource: !!userInfo.appsumo_license_uuid
          ? 'appsumo'
          : !!userInfo.stripe_customer_id
          ? 'stripe'
          : null,
        premiumPlanName: userInfo.current_premium.plan_name,
        premiumPriceId: userInfo.current_premium.price_id,
        premiumProductId: userInfo.current_premium.product_id
      });
    }
    posthog.identify(userInfo.id, trackingData);
  };

  const hasPremium = useMemo<PLAN_OPTIONS | null>(() => {
    if (!user) return null;
    return (user?.current_premium?.plan_name as PLAN_OPTIONS) || 'free';
  }, [user]);

  const hasPremiumOrNot = () => {
    return user?.current_premium != null;
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser) => {
        setUserAuth(firebaseUser);
        if (!!firebaseUser) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            user_id: `${firebaseUser.email}${firebaseUser.uid}` //this number must be replaced with an actual User ID
          });
          if (window.sendinblue) {
            window.sendinblue.identify(firebaseUser.email, {
              FIRSTNAME: firebaseUser.displayName,
              id: firebaseUser.uid
            });
          }
          posthog.identify(
            firebaseUser.uid, // Replace 'distinct_id' with your user's unique identifier
            {
              email: firebaseUser.email,
              id: firebaseUser.uid,
              displayName: firebaseUser.displayName,
              lastName: firebaseUser
            } // optional: set additional user properties
          );
          Sentry.setUser({ id: firebaseUser.uid, email: firebaseUser.email });
          await checkWaitlistStatus(firebaseUser);
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ false)
            .then(async (accessToken) => {
              localStorage.setItem(LocalStorageItems.ACCESS_TOKEN, accessToken);
              localStorage.setItem(
                LocalStorageItems.URL_SLUG,
                firebaseUser.uid
              );
              refreshMyInfo(accessToken);
            })
            .catch(function (error) {
              // Handle error
              console.error('error');
              console.error(error);
            });
        } else {
          setHasWaitlistPermission('nope');
          localStorage.removeItem('accessToken');
          if (window.location.pathname.includes(PageNames.EXPORT_SLIDESHOW)) {
            router.push('/login');
          }
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        userAuth,
        hasWaitlistPermission,
        refreshMyInfo,
        hasPremium,
        hasPremiumOrNot
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a MenuContext');
  }
  return context;
};
